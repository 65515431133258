
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 
.slick-prev:before,
.slick-next:before {
  color: black!important;

}
 */
 .mobile-container {
  /* max-width: 90%; */
  /* margin-right: 0;
  margin-left: 0; */
  padding-bottom: 1rem;
  /* background-color: blue; */
}

.back-red {
  background-color: "red";
}

/* .react-datepicker {
  position: absolute !important;
  right: 1%;
  z-index: 999;
  
} */

#reactgooglegraph-2, #reactgooglegraph-1 {
  display: flex!important;
  justify-content: center!important;
 
}