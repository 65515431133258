.main {
  
    text-align: center;
    justify-content: center;
    display: flexbox; 
    padding-top: 90px;
    padding-bottom: 250px;
    background-color: #e1e3eb;
    background-image: url(../assets/images/pexels-pixabay-46169.png);
    background-position: center;
    background-size: cover;
  }
  .sub-main {
    display: flex;
    justify-content: center;
    height: 550px;
    width: 35%;
    box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
    padding-top: 30px;
    border-radius: 60px; 
    background-color: white;
  }
  /* .img {
      padding-top: 20px;
      justify-content: center;
      display: flex;
    }
  .container-image {
    background-color: rgb(223, 221, 221); 
    border-radius: 150px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 115px;
    width: 115px;
  } */
  /* .profile {
    height: 100px;
    width: 100px;
    border-radius: 130px;
  }  */

  input{
      width: 300px;
      height: 50px;
      border-radius: 60px;
      box-shadow: inset 0px 0px 25px 0px #888;
      border: none;
      outline: none;
      background-color: #fff;
  }

  .email{
      height: 25px;
      width: 25px;
      position: absolute;
      padding: 14px 0 0 25px;
  }

  .name{
      padding-left: 20px;
      font-size: 20px;
  }
  .second-input{
      padding-top: 20px;
  }

  .login-button{
    width: 380px;
    height: 50px;
    border-radius: 60px;
    background-color: #b90ee8;
    color: white;
    font-size: 25px;
    border: none;
    cursor: pointer;
}
.login-div{
    padding-top: 25px;
}

  .login-link{
      font-size: 25px;
      font-weight: 400;
  }
  
  a{
       color: rgb(24, 24, 27);
   }
 