.signup {
    text-transform: uppercase;
    color: white;
    transition: .3s;
    padding: 5px;
    background-color: #eef2f3;
    color: black;
}


 
.signin {
    padding: 5px;
    background-color: #eef2f3;
    color: black;
    width: 100%;
}

.bold-line {
    background: #e7e7e7;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 100%;
    height: 360px;
    z-index: 1;
    opacity:0.1;
    background-size:cover;
  }

  .password-container {
    display: flex;
    display: flex; 
    box-align: center;
    align-items: center;
    align-items: center;
    box-pack: center;
    justify-content: center;
    background: #eee;
    height: 90vh;
    background: linear-gradient(to right, #D6A4A4, #DAE2F8);
  }

  .overlay {
    background: linear-gradient(#8CA6DB, #B993D6);
    opacity: 0.85;
    filter: alpha(opacity=85);
    height: 630px;
    position: absolute;
    width: 360px;
    z-index: 1;
  }

  .reset-content {
    padding-left: 25px;
    padding-right: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    z-index: 5;
  }

  .welcome {
    font-weight: 200;
    margin-top: 40px;
    text-align: center;
    font-size: 40px;
    font-size: 2.5rem;
    letter-spacing: 0px;
    letter-spacing: 0.05rem;
  }

  .subtitle {
    text-align: center;
    line-height: 1em;
    font-weight: 100;
    letter-spacing: 0px;
    letter-spacing: 0.02rem;
    margin-top: 20px;
  }

  .input-fields {
    margin-top: 25px;
  }

  ::input-placeholder .input-line:focus +::input-placeholder {
    color: #fff;
  }

  .input-line {
    background: none;
    margin-bottom: 10px;
    line-height: 2.4em;
    color: #fff;
    font-family: roboto;
    font-weight: 300;
    letter-spacing: 0px;
    letter-spacing: 0.02rem;
    font-size: 19px;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.65);
    transition: all .2s ease;
    padding-left: 20px;
  }
  
  .full-width {
    width: 100%;
  }

  .ghost-round {
    cursor: pointer;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.65);
    border-radius: 25px;
    color: rgba(255, 255, 255, 0.65);
    align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-size: 19px;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2.5em;
    margin-top: 20px;
    margin-bottom: 25px;
    transition: all .2s ease;
  }
  
  .ghost-round:hover {
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    transition: all .2s ease;
  }